import { createContext, useContext } from 'react';

import type { SiteSettings } from '@/types/shared';

type SiteSettingsContextType = {
  siteSettings: SiteSettings;
};

const SiteSettingsContext = createContext<SiteSettingsContextType>(null);

export const SiteSettingsProvider = ({ siteSettings, children }) => {
  return (
    <SiteSettingsContext.Provider value={{ siteSettings }}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export const useSiteSettings = () => {
  const context = useContext(SiteSettingsContext);

  return context?.siteSettings || null;
};
