import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';

import { screen } from '@/components/common/breakpoints';
import { IconOnlyButton } from '@/components/common/Buttons';

import type { HTMLProps, ReactNode } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const data = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const { context } = data;

  const click = useClick(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [isOpen, setIsOpen, interactions, data, labelId, descriptionId],
  );
};

const ModalContext = createContext(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};

export const Modal = ({ children }: { children: ReactNode }) => {
  const modal = useModal();
  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
};

interface ModalTriggerProps {
  children: ReactNode;
  asChild?: boolean;
}

export const ModalTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & ModalTriggerProps
>(function ModalTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useModalContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.isOpen ? 'open' : 'closed',
      }),
    );
  }

  return (
    <TriggerButton
      ref={ref}
      data-state={context.isOpen ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </TriggerButton>
  );
});

const TriggerButton = styled.button`
  display: inline-block;
  width: fit-content;
  all: unset;
  outline: revert;
  cursor: pointer;
`;

export const ModalContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(function ModalContent(props, propRef) {
  const { context: floatingContext, ...context } = useModalContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <FloatingModalOverlay className="Modal-overlay" lockScroll>
        <FloatingFocusManager context={floatingContext}>
          <FloatingModalInner
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </FloatingModalInner>
        </FloatingFocusManager>
      </FloatingModalOverlay>
    </FloatingPortal>
  );
});

const FloatingModalOverlay = styled(FloatingOverlay)`
  background-color: #1b1b41b8;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: var(--spacing-xx-small);

  ${screen.md} {
    padding: var(--spacing-small);
  }
`;

const FloatingModalInner = styled.div`
  text-align: right;
  width: 100%;
  height: auto;
  max-width: 920px;

  ${screen.xl} {
    max-width: 55%;
  }
`;

export const ModalHeading = forwardRef<
  HTMLHeadingElement,
  HTMLProps<HTMLHeadingElement>
>(function ModalHeading({ children, ...props }, ref) {
  const { setLabelId } = useModalContext();
  const id = useId();

  useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <h2 {...props} ref={ref} id={id}>
      {children}
    </h2>
  );
});

export const ModalDescription = forwardRef<
  HTMLParagraphElement,
  HTMLProps<HTMLParagraphElement>
>(function ModalDescription({ children, ...props }, ref) {
  const { setDescriptionId } = useModalContext();
  const id = useId();

  useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return (
    <p {...props} ref={ref} id={id}>
      {children}
    </p>
  );
});

export const ModalClose = () => {
  const { setIsOpen } = useModalContext();
  return (
    <CloseButton
      aria-label="close popup"
      onClick={() => setIsOpen(false)}
      icon="Close"
      variant="flat-light"
      size="md"
    />
  );
};

const CloseButton = styled(IconOnlyButton)`
  margin-bottom: var(--spacing-150);
`;
