import styled from '@emotion/styled';

import { RichHeading } from '@/components/common/Heading';
import { Icon } from '@/components/common/Icon';
import { Image } from '@/components/common/image';
import { TooltipV2 } from '@/components/common/TooltipV2';
import { bodySmall } from '@/styles/typography';

import type { TextColourTokenType } from '@/types/colours';
import type { SanityTooltipButtonType } from '@/types/sanity';

export interface TooltipButtonTriggerProps extends SanityTooltipButtonType {
  textColour?: TextColourTokenType;
}

const TooltipButtonTrigger = ({
  buttonText,
  buttonIcon,
  tooltip,
  trackingTag,
  textColour,
}: TooltipButtonTriggerProps) => {
  return (
    <TooltipV2 key="tooltipText" mark={tooltip} textColour={textColour}>
      <Trigger
        textColour={textColour}
        className={
          trackingTag?.blockName &&
          `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
        }
      >
        {buttonIcon?.asset ? (
          <Image {...buttonIcon} sizes="20px" />
        ) : (
          <Icon icon="InfoCircle" size="18px" />
        )}
        {!!buttonText && (
          <RichHeading elementType="paragraph" heading={buttonText} />
        )}
      </Trigger>
    </TooltipV2>
  );
};

const Trigger = styled.div<{ textColour?: TextColourTokenType }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    color: ${({ textColour }) =>
      textColour ? `var(${textColour})` : 'var(--text-strong)'};
    ${bodySmall}
    margin-left: var(--spacing-xxx-small);
  }

  > img {
    width: 20px !important;
  }
`;

export { TooltipButtonTrigger };
