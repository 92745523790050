import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { resolveSize, RichHeading } from '@/components/common/Heading';
import { Paragraph } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { isDarkBackground } from '@/lib/colourUtils';
import { colourTokens } from '@/styles/colours';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { TypographySizeTypes } from '@/styles/typography';
import type { BackgroundColourTokenType } from '@/types/colours';
import type { BlockWithAudience } from '@/types/shared';
import type { SpacingVariable } from '@/types/tokens';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export type AlignmentOptions = 'start' | 'center' | 'end';

type PaddingOptions = {
  caption?: SpacingVariable;
  captionMobile?: SpacingVariable;
  description?: SpacingVariable;
  descriptionMobile?: SpacingVariable;
};

export interface CaptionHeadingDescriptionProps extends BlockWithAudience {
  caption?: string;
  heading?: HeadingSanityBlockType;
  description?:
    | Array<
        | SanityKeyed<SanityBlock>
        // Allows youtube objects to be passed.
        | SanityKeyed<{
            _type: 'youtube';
            url?: string;
            caption?: string;
          }>
      >
    | string;
  desktopAlignment?: AlignmentOptions;
  mobileAlignment?: AlignmentOptions;
  padding?: PaddingOptions;
  isCaptionUppercase?: boolean;
  defaultHeadingSize?: TypographySizeTypes;
  className?: string;
  marginBottom?: SpacingVariable;
  backgroundColourToken?: BackgroundColourTokenType;
  heroTextColourToken?: '--text-strong' | '--text-inverse-strong';
}

export const CaptionHeadingDescription = ({
  caption,
  heading,
  description,
  desktopAlignment = 'start',
  mobileAlignment = 'start',
  marginBottom = 'x-large',
  padding,
  isCaptionUppercase = false,
  defaultHeadingSize = 'heading-lg',
  className,
  backgroundColourToken,
  heroTextColourToken,
  pageAudience,
  isAudienceSwitcherEnabled,
}: CaptionHeadingDescriptionProps) => {
  const captionTextColour = ():
    | '--text-warm-base'
    | '--text-inverse-warm-base' => {
    if (heroTextColourToken) {
      return heroTextColourToken === '--text-inverse-strong'
        ? colourTokens.text.inverseWarmBase.token
        : colourTokens.text.warmBase.token;
    }

    return isDarkBackground(backgroundColourToken)
      ? colourTokens.text.inverseWarmBase.token
      : colourTokens.text.warmBase.token;
  };

  return (
    <CaptionHeadingDescriptionContainer
      desktopAlignment={desktopAlignment}
      mobileAlignment={mobileAlignment}
      className={className}
      marginBottom={marginBottom}
    >
      {caption && (
        <Caption
          size="label-lg"
          className="caption"
          paddingBottom={padding?.caption || 'small'}
          mobilePaddingBottom={padding?.captionMobile || 'small'}
          isCaptionUppercase={isCaptionUppercase}
          textColourToken={captionTextColour()}
        >
          {caption}
        </Caption>
      )}
      {heading && (
        <RichHeading
          heading={heading}
          size={resolveSize(heading, defaultHeadingSize)}
        />
      )}
      {description && (
        <DescriptionContainer
          className="description"
          paddingTop={padding?.description || 'large'}
          mobilePaddingTop={padding?.descriptionMobile || 'large'}
        >
          {typeof description === 'string' ? (
            <Paragraph size="subheading-sm">{description}</Paragraph>
          ) : (
            <PortableText
              value={description}
              audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
            />
          )}
        </DescriptionContainer>
      )}
    </CaptionHeadingDescriptionContainer>
  );
};

const CaptionHeadingDescriptionContainer = styled.div<{
  mobileAlignment: AlignmentOptions;
  desktopAlignment: AlignmentOptions;
  marginBottom: SpacingVariable;
}>`
  display: flex;
  flex-direction: column;

  align-items: ${({ mobileAlignment }) => `${mobileAlignment}`};
  text-align: ${({ mobileAlignment }) => `${mobileAlignment}`};
  justify-items: ${({ mobileAlignment }) => `${mobileAlignment}`};

  ${screen.md} {
    align-items: ${({ desktopAlignment }) => `${desktopAlignment}`};
    text-align: ${({ desktopAlignment }) => `${desktopAlignment}`};
    justify-items: ${({ desktopAlignment }) => `${desktopAlignment}`};
  }
`;

const Caption = styled(Paragraph)<{
  paddingBottom: SpacingVariable;
  mobilePaddingBottom: SpacingVariable;
  isCaptionUppercase: boolean;
  textColourToken: '--text-warm-base' | '--text-inverse-warm-base';
}>`
  color: ${({ textColourToken }) => `var(${textColourToken});`}
  
  text-transform: ${({ isCaptionUppercase }) =>
    isCaptionUppercase ? 'uppercase' : 'none'};

  ${(p) =>
    p.mobilePaddingBottom &&
    `padding-bottom: var(--spacing-${p.mobilePaddingBottom});`}
  ${screen.md} {
    ${(p) =>
      p.paddingBottom && `padding-bottom: var(--spacing-${p.paddingBottom});`}
  }
`;

const DescriptionContainer = styled.div<{
  paddingTop: SpacingVariable;
  mobilePaddingTop: SpacingVariable;
}>`
  ${(p) =>
    p.mobilePaddingTop && `padding-top: var(--spacing-${p.mobilePaddingTop});`}
  ${screen.lg} {
    ${(p) => p.paddingTop && `padding-top: var(--spacing-${p.paddingTop});`}
  }
`;
