import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { getTextColour } from '@/lib/colourUtils';
import { labelLarge } from '@/styles/typography';

import type {
  DecorativeColourTokenType,
  DecorativeColourType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';

export interface TagProps {
  bgColour?: DecorativeColourType;
  textColour?: TextColourTokenType;
  children: string | ReactNode;
  pill?: boolean;
  margin?: string;
}

export const Tag = ({
  children,
  bgColour: { token: bgColourToken } = {
    title: 'eclipse / eclipse-200',
    value: 'rgb(221, 252, 157)',
    token: '--decorative-eclipse',
  },
  textColour,
  pill = false,
  margin = '0 0 var(--spacing-small) 0',
}: TagProps) => {
  return (
    <TagContainer
      bgColour={bgColourToken}
      textColour={textColour || getTextColour(bgColourToken)}
      pill={pill}
      margin={margin}
    >
      {children}
    </TagContainer>
  );
};

const TagContainer = styled.span<{
  bgColour: DecorativeColourTokenType;
  textColour: TextColourTokenType;
  pill: boolean;
  margin: string;
}>`
  ${labelLarge}
  display: inline-block;
  width: fit-content;
  padding: var(--spacing-xxx-small) var(--spacing-xx-small);
  border-radius: ${(props) =>
    props.pill ? 'var(--radius-l)' : 'var(--radius-xxs)'};

  background-color: ${(props) => `var(${props.bgColour})`};
  color: ${(props) =>
    (props.textColour && `var(${props.textColour})`) ||
    '--text-inverse-strong'};
  ${(props) => props.margin && `margin: ${props.margin}`};

  ${screen.md} {
    padding: var(--spacing-xx-small);
  }

  ${screen.lg} {
    padding: var(--spacing-xx-small) var(--spacing-x-small);
  }
`;
