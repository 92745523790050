import Script from 'next/script';

import { useModalContext } from '@/components/common/Modal';

export interface WistiaEmbedProps {
  videoId: string;
  inline?: boolean;
}

const WistiaContent = ({ videoId }) => (
  <>
    <Script
      src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
      strategy="lazyOnload"
    />
    <Script
      src="https://fast.wistia.com/assets/external/E-v1.js"
      strategy="lazyOnload"
    />
  </>
);

const ModalContext = ({ videoId }) => {
  const context = useModalContext();

  if (context.isOpen) return <WistiaContent videoId={videoId} />;
  return null;
};

const WistiaEmbed = ({ videoId, inline }: WistiaEmbedProps) => {
  return (
    <>
      {!inline ? (
        <ModalContext videoId={videoId} />
      ) : (
        <WistiaContent videoId={videoId} />
      )}
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
          }}
        >
          <div
            className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: '0',
                opacity: '0',
                overflow: 'hidden',
                position: 'absolute',
                top: '0',
                transition: 'opacity 200ms',
                width: '100%',
              }}
            >
              <img
                src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%',
                }}
                alt=""
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WistiaEmbed };
